import React from "react";

function Vk() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 32 32"
        >
            <path d="M.094 7.75c0-1.188.813-1.656 1.813-1.719l4.656.031c.281 0 .531.188.625.469 1.063 3.438 2.375 5.563 3.938 7.969.094.188.25.281.406.281.125 0 .25-.063.344-.219l.094-.344.031-5.406c0-.781-.375-.906-1.25-1.031a.7.7 0 01-.563-.688c0-.063 0-.125.031-.188.438-1.344 1.813-2.031 3.75-2.031l1.75-.031c1.438 0 2.75.625 2.75 2.469v7.094c.125.094.25.156.406.156.25 0 .563-.156.813-.563 1.625-2.281 3.469-5 3.719-6.438 0-.063.031-.094.063-.156.344-.688 1.219-1.156 1.594-1.281a.627.627 0 01.281-.063h4.844l.313.031c.469 0 .813.313.969.594.281.438.219.906.25 1.094v.219c-.469 2.844-3.719 6.031-5.094 8.094-.188.25-.281.469-.281.688 0 .188.094.375.25.563l4.563 5.75c.25.344.375.75.375 1.094 0 1.031-.969 1.625-1.906 1.719l-.531.031h-4.75c-.094 0-.156.031-.25.031-.531 0-.969-.281-1.281-.594-1-1.219-1.969-2.469-2.938-3.688-.188-.25-.25-.281-.438-.406-.219.906-.406 1.844-.625 2.781l-.094.531c-.156.563-.563 1.156-1.313 1.313l-.438.031h-3.063c-5.406 0-10.25-7.688-13.656-17.281a2.747 2.747 0 01-.156-.906zm18.781 8.094c-.813 0-1.719-.469-1.719-1.344V7.312c0-.844-.375-1.156-1.406-1.156l-1.781.063c-1 0-1.563.156-2.031.469.719.344 1.375.813 1.375 2.125v5.5c-.094 1.094-1 1.813-1.875 1.813-.594 0-1.125-.344-1.438-.906-1.406-2.125-2.594-4.125-3.625-7l-.281-.813-4.156-.031c-.563 0-.5.031-.5.313 0 .188.031.438.063.594l.656 1.75c3.406 8.813 7.688 14.594 11.75 14.594h3.125c.438 0 .406-.531.5-.844l.594-2.75c.125-.281.219-.531.438-.75.25-.25.531-.344.813-.344.594 0 1.156.469 1.531.906l2.656 3.375c.219.344.406.406.531.406h5.156c.5 0 .938-.156.938-.469a.6.6 0 00-.094-.313l-4.531-5.656c-.375-.469-.531-.938-.531-1.406 0-.5.188-1 .5-1.438 1.313-1.969 4.125-4.781 4.781-7.094l.094-.406c-.031-.156-.031-.281-.063-.438h-4.906a1.702 1.702 0 00-.75.563l-.188.594c-.719 2-2.688 4.75-4.094 6.656-.469.438-1 .625-1.531.625z"></path>
        </svg>
    );
}

export { Vk };
