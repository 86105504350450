import React from "react";

function Inst() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
    >
      <g>
        <path d="M23.9 7.1c-.1-1.3-.3-2.1-.6-2.9-.2-.8-.6-1.5-1.3-2.2S20.7.9 19.9.6C19.1.3 18.3.1 17 0h-5C8.7 0 8.3 0 7.1.1c-1.3 0-2.2.2-3 .5C3.4.9 2.7 1.3 2 2S.9 3.4.6 4.1c-.3.8-.5 1.7-.5 3C0 8.3 0 8.7 0 12s0 3.7.1 4.9c.1 1.3.3 2.1.6 2.9.2.8.6 1.5 1.3 2.2s1.3 1.1 2.1 1.4c.8.3 1.6.5 2.9.6h5c3.3 0 3.7 0 4.9-.1 1.3-.1 2.1-.3 2.9-.6.8-.3 1.5-.7 2.1-1.4s1.1-1.3 1.4-2.1c.3-.8.5-1.6.6-2.9.1-1.2.1-1.6.1-4.9s0-3.7-.1-4.9zm-2.1 9.7c-.1 1.2-.2 1.8-.4 2.2-.2.6-.5 1-.9 1.4s-.8.7-1.4.9c-.4.2-1.1.4-2.2.4-1.3.1-1.6.1-4.8.1s-3.6 0-4.8-.1c-1.2-.1-1.8-.2-2.2-.4-.6-.2-1-.5-1.4-.9s-.7-.8-.9-1.4c-.2-.4-.4-1.1-.4-2.2-.1-1.3-.1-1.6-.1-4.8s0-3.6.1-4.8c-.1-1.2.1-1.9.2-2.3.2-.6.5-1 .9-1.4s.8-.7 1.4-.9c.4-.1 1.1-.3 2.3-.4H12c3.2 0 3.6 0 4.8.1 1.2.1 1.8.2 2.2.4.6.2 1 .5 1.4.9s.7.8.9 1.4c.2.4.4 1.1.4 2.2.1 1.3.1 1.6.1 4.8v4.8z"></path>
        <path d="M12 6c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 9.9c-2.2 0-3.9-1.7-3.9-3.9S9.8 8.1 12 8.1s3.9 1.7 3.9 3.9-1.7 3.9-3.9 3.9z"></path>
        <circle cx="18.5" cy="6" r="1.5"></circle>
      </g>
    </svg>
  );
}

export { Inst };