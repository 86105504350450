import React from 'react';
import Main from "../../images/about/main.jpg";
import Diplom1 from "../../images/about/diplom1.png";
import Diplom2 from "../../images/about/diplom2.jpg";
import "./about.scss";
import { ImageCard } from './image-card';

function About() {
    return (
        <div className='about'>
            <div className='about-images'>
                <img className='about-images-main image' src={Main} />
                <div className='about-images-list'>
                    {/* <ImageCard image={Diplom1} title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                    <ImageCard image={Diplom2} title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
                </div>
            </div>
            <div className="about-text">
                <p><b>Светлана Юрьева художник-автодидакт.</b> Родилась в 1968 году в городе Балашов Саратовской области.
                    Окончила на отлично Балашовскую детскую художественную школу, но,
                    в связи со сложной экономической ситуацией в стране, учиться дальше на художника не получилось.
                    Выучилась на бухгалтера-ревизора. Потом работала на Крайнем Севере - в Норильске. Там уже получила диплом металлурга.
                    В 2021 году вместе с семьей переехала жить в Санкт-Петербург, где и проживаю до сих пор.
                </p>
                <p>Живописью занималась всю жизнь, но с 2013 года стала активно выставляться. Пишу на заказ портреты по фото, работаю в анималистическом жанре и жанре пейзажа.
                    На данный момент многие мои работы находятся в частных коллекциях.
                    Также в 2020 году прошла обучение в АНО НИИДПО с присвоением квалификации «Преподаватель изобразительного искусства» и начала проводить занятия живописи с детьми от трех лет в Детском развивающем центре в Норильске и взрослыми.
                    В Санкт-Петербурге открыла свою Арт-студию в декабре 2023 года, где также провожу обучение детей и мастер-классы для взрослых.
                </p>
                <h4>Участник групповых региональных, Всероссийских и международных выставок:</h4>
                <p className="bold">2013 – 2019 – Фестиваля творчества работников компании Норильский никель «Корпорация звезд», ДК Норильского Комбината г. Норильска</p>
                <p className="bold">2020 – Третьей международной выставки «Портрет кошки», Выставочный Центр Санкт-Петербургского союза художников (выпущен каталог, куратор Сергей Гаркави)</p>
                <p className="bold">2020 – Безвозмездное участие в создании календаря для Благотворительного фонда «69-я параллель», г. Норильска</p>
                <p className="bold">2021 – Городская выставка ДПИ «Февральские самоцветы», организатор Управление по делам культуры и искусства администрации г. Норильска</p>
                <p className="bold">2021 – Четвертая международная выставка «Портрет кошки», Выставочный Центр Санкт-Петербургского союза художников (выпущен каталог, куратор Сергей Гаркави)</p>
                <p className="bold">2022 – Международный конкурс реалистического искусства «Реализм – 2.0», Выставочный Центр Санкт-Петербургского союза художников (организатор – Евразийский художественный союз)</p>
                <p className="bold">2022 – Приз зрительских симпатий - Всероссийский конкурс и выставка художественных работ «Семья – душа России», Государственный музей-заповедник Царицыно, г. Москва (организатор Фонд социально-культурных инициатив РФ)</p>
                <p className="bold">2022 - Международный конкурс современного искусства «Талант России» - Выставочные залы Союза художников России в Новой Третьяковке (организатор – Евразийский художественный союз)</p>
                <p className="bold">2022 – 2023 – выставки от сообщества «Новые передвижники» город Санкт-Петербург</p>
                <p className="bold">2023 – участник градозащитного пленэра «Цветущая Нарвская застава», организатор Муниципальное образование Нарвский округ, сообщество «Новые передвижники»</p>
                <p className="bold">2023 –Всероссийский художественный конкурс живописи и ДПИ «Палитра талантов», организатор - Фонд развития культуры и образования "НОВАГРАНТ", Таврида Арт (Лауреат II степени)</p>
                <p className="bold">2023 – Международная выставка & конкурс флористического искусства «Цветочное царство», выставочный Центр Санкт-Петербургского союза художников (организатор – Евразийский художественный союз)</p>
                <p className="bold">2023 – Санкт-Петербургская неделя искусств, Выставочный Центр Санкт-Петербургского союза художников (организатор – Евразийский художественный союз)</p>
                <p className="bold">2023 – Выставка «Арт-фауна», Большой зал Выставочного центра Санкт-Петербургского Союза художников (организатор ООО «ПИАРТЕК» по поручению Санкт-Петербургского Союза художников)</p>
                <p className="bold">Член Союза Русских художников, Член Союза Акварелистов России</p>

            </div>
        </div>
    )
}

export { About };
